import { authApi } from './config'

// Запросы для пользователей

export const getSelectedClubs = async () => {
    try {
        const response = await authApi.get('/club/selected/')
        return response.data
    } catch (err) {
        return err
    }
}

// Запросы для админов

export const getAllClubs = async () => {
    try {
        const response = await authApi.get('/club/all/')
        return response.data
    } catch (err) {
        return err
    }
}

export const createOneClub = async (name) => {
    try {
        const response = await authApi.post('/club', { name })
        return response.data
    } catch (err) {
        return err
    }
}

export const setSelectedClubs = async (clubs) => {
    try {
        const response = await authApi.post('/club/set-selected', { clubs })
        return response.data
    } catch (err) {
        return err
    }
}

export const deleteClubById = async (clubId) => {
    try {
        const response = await authApi.delete('/club/' + clubId)
        return response.data
    } catch (err) {
        return err
    }
}
