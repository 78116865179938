import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { AppContext } from 'src'

export const WheelConfetti = observer(() => {
    const { wheelStore } = useContext(AppContext)
    const { hideWheel } = wheelStore.state
    const confettiClassNames = 'wheel-container__confetti'
        + (hideWheel ? ' wheel-container__confetti--fade-out' : '')

    return (
        <svg className={confettiClassNames} width='319' height='369' viewBox='0 0 319 369' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_290_1524)'>
                <path d='M34.7571 22.4528C34.2889 20.5827 33.2959 18.8627 31.8773 17.4649C31.5688 18.2545 31.2515 18.8964 30.9252 19.3906C31.9998 20.987 32.0998 23.1461 31.7961 25.0032C30.5481 32.3743 23.7726 37.5857 18.4392 42.1173C18.243 42.3071 18.1945 42.4879 18.1792 42.7261C20.4328 41.7308 24.1947 40.1704 27.3769 39.3269C32.1677 34.6499 36.3053 28.9739 34.7571 22.4528Z' fill='url(#paint0_linear_290_1524)' />
                <path d='M44.1049 45.2633C43.5196 42.9257 41.7701 40.9546 39.6547 39.8061C36.0658 37.8267 31.6418 38.2405 27.3438 39.2695C24.1616 40.113 20.3997 41.6734 18.1461 42.6687C18.2655 43.67 20.1032 45.1316 20.8573 44.8491C25.1376 43.5245 41.1712 37.4017 41.2247 45.7031C41.2633 48.815 40.8166 51.7484 41.7966 54.2403L43.7677 52.4908C43.7977 50.0273 44.456 46.6659 44.1049 45.2633Z' fill='#5BD3DD' />
                <path d='M10.9407 28.8646C10.5305 28.9485 10.1445 28.942 9.88861 28.6311C9.63267 28.3203 10.9796 26.5488 11.3058 26.0547C12.2361 24.7532 13.3803 23.5575 14.6238 22.5338C17.1682 20.4533 20.2882 18.5755 23.4372 17.6747C24.8067 17.2662 26.3085 17.0872 27.6892 17.3602C28.0728 16.833 28.5621 16.0918 28.8375 15.2448C24.9862 13.3405 20.8448 14.5084 17.2484 16.3555C14.5208 17.8538 7.60398 22.6881 6.76073 26.9206L6.73651 27.0111C6.65733 27.6683 6.69282 28.2594 6.87607 28.8416C7.42991 29.6685 8.84131 29.4651 10.9407 28.8646Z' fill='url(#paint1_linear_290_1524)' />
                <path d='M32.6615 7.30387C31.7872 4.59802 28.7846 1.51567 25.7986 2.16939L27.0943 5.4728C31.2641 3.29474 29.7479 13.114 29.0009 14.9977C28.9766 15.0881 28.9524 15.1785 28.8951 15.2116C28.6197 16.0586 28.1303 16.7998 27.7468 17.327C26.2787 19.5506 24.1599 21.309 21.9991 22.8623C18.8483 25.2165 15.5079 27.3744 11.7193 28.4916C11.5142 28.5335 11.1371 28.6748 10.8747 28.7499C8.77536 29.3504 7.36395 29.5537 6.84321 28.7842C7.16774 29.7434 7.95977 30.5857 9.27664 31.2778C12.5854 33.0368 17.0813 30.3646 19.9478 28.7096C23.6985 26.4677 27.8901 23.6655 30.4273 19.9838C30.5904 19.7368 30.8108 19.4566 30.9408 19.1522C31.2097 18.6912 31.5844 18.0162 31.8929 17.2265C33.1689 14.273 33.5292 10.3957 32.6615 7.30387Z' fill='#5BD3DD' />
            </g>
            <path d='M35.3572 90.0517C37.3634 89.5579 39.3426 89.4791 41.3295 90.0783C42.3506 90.3537 43.3716 90.6291 44.5928 91.0218C44.8684 90.7793 45.2473 90.5435 45.3711 90.239C46.1686 88.3631 45.8156 87.402 43.8421 86.5952C41.8136 85.8369 39.7164 85.3347 37.654 85.0953C36.4677 84.9655 35.2678 85.0432 34.0747 85.0171C33.6057 85.0385 33.1368 85.0598 32.6341 85.6001C32.3809 87.0948 32.7484 88.6301 33.1227 90.0616C33.9717 90.5863 34.6746 90.1633 35.3572 90.0517Z' fill='url(#paint2_linear_290_1524)' />
            <path d='M296.101 36.493C294.463 37.7555 293.165 39.2563 292.389 41.1869C291.966 42.1586 291.542 43.1303 291.084 44.3321C290.724 44.3963 290.303 44.5458 289.989 44.4524C288.031 43.9048 287.503 43.0277 288.108 40.978C288.786 38.9155 289.706 36.9593 290.808 35.1945C291.448 34.1843 292.259 33.2931 292.984 32.3424C293.294 31.9885 293.603 31.6347 294.338 31.5786C295.659 32.3143 296.625 33.5615 297.505 34.7492C297.383 35.7419 296.614 36.0281 296.101 36.493Z' fill='url(#paint3_linear_290_1524)' />
            <path d='M270.388 362.951C269.277 361.203 268.567 359.348 268.494 357.268C268.426 356.21 268.358 355.152 268.337 353.865C268.019 353.682 267.675 353.397 267.348 353.377C265.32 353.222 264.527 353.867 264.399 356C264.335 358.171 264.534 360.325 264.971 362.361C265.229 363.53 265.688 364.644 266.047 365.786C266.218 366.225 266.388 366.663 267.06 366.967C268.552 366.728 269.884 365.885 271.115 365.07C271.337 364.095 270.712 363.563 270.388 362.951Z' fill='url(#paint4_linear_290_1524)' />
            <g clipPath='url(#clip1_290_1524)'>
                <path d='M304.383 323.057C305.504 322.77 306.624 322.77 307.745 322.914C309.495 323.129 311.176 323.631 312.787 324.349C314.187 324.994 315.518 325.784 316.779 326.788C317.899 327.721 318.95 328.726 319.86 329.874C319.93 330.017 320 330.232 320 330.448C318.529 330.591 317.199 331.165 315.728 331.524L315.658 331.452C315.308 331.022 314.958 330.591 314.538 330.161C313.417 328.941 312.087 327.865 310.686 327.004C309.145 326.071 307.465 325.281 305.644 324.851C304.733 324.636 303.753 324.42 302.773 324.349C302.492 324.349 302.282 324.42 302.072 324.492C302.702 323.99 303.333 323.559 303.963 323.201C304.103 323.129 304.243 323.057 304.383 323.057Z' fill='url(#paint5_linear_290_1524)' />
                <path d='M304.383 342.072C304.523 341.928 304.593 341.785 304.663 341.641C304.873 341.139 304.943 340.565 304.803 339.991C304.663 339.273 304.453 338.628 304.173 337.982C303.333 336.188 302.142 334.825 300.532 333.748C299.971 333.39 299.411 333.031 298.851 332.744C298.781 332.744 298.711 332.672 298.571 332.672C298.361 331.237 298.571 329.945 298.711 329.3C298.711 329.371 298.711 329.443 298.641 329.587C298.781 329.658 298.921 329.658 298.991 329.658C299.411 329.802 299.831 329.945 300.251 330.089C301.582 330.591 302.913 331.165 304.173 331.955C305.294 332.672 306.274 333.461 307.114 334.538C308.025 335.757 308.725 337.121 309.005 338.628C309.145 339.13 309.145 339.704 309.145 340.206C309.075 340.565 309.005 340.924 308.865 341.211C308.795 341.282 308.725 341.426 308.725 341.498C308.725 341.569 308.655 341.569 308.655 341.641L308.585 341.713C308.515 341.856 308.375 341.928 308.235 342.072C307.885 342.359 307.535 342.646 307.114 342.789C306.414 342.789 305.574 342.502 304.383 342.072Z' fill='url(#paint6_linear_290_1524)' />
                <path d='M272.029 308.85C272.449 308.707 272.87 308.635 273.36 308.491C273.71 308.42 273.99 308.276 274.34 308.133C274.41 308.133 274.48 308.133 274.55 308.133C274.62 308.276 274.62 308.42 274.62 308.491C274.48 308.778 274.27 309.065 274.13 309.352C273.64 310.429 273.22 311.505 272.87 312.581C272.73 313.084 272.589 313.658 272.519 314.232C272.519 314.662 272.449 315.021 272.449 315.451C272.449 316.743 272.8 317.891 273.5 318.967C274.2 320.044 275.111 320.976 276.091 321.837C276.721 322.34 277.352 322.698 277.982 323.129C279.032 323.775 280.153 324.349 281.273 324.779C283.934 325.856 286.666 326.717 289.467 327.291C291.148 327.649 292.758 328.08 294.439 328.439C294.509 328.439 294.579 328.439 294.719 328.51C294.789 328.439 294.789 328.367 294.859 328.295C295.139 327.649 295.489 327.075 295.98 326.573C295.77 326.86 294.159 328.869 294.089 330.95C294.019 330.95 293.949 330.878 293.809 330.878C293.178 330.663 292.548 330.519 291.918 330.304C290.027 329.73 288.066 329.371 286.105 328.869C283.794 328.367 281.483 327.721 279.172 326.932C277.352 326.286 275.531 325.569 273.92 324.564C273.29 324.205 272.659 323.775 272.029 323.344C271.049 322.627 270.208 321.766 269.508 320.761C268.598 319.613 268.178 318.321 268.037 316.886C267.967 315.523 268.037 314.232 268.458 312.94C268.528 312.725 268.598 312.438 268.668 312.222C268.948 311.361 269.298 310.429 269.718 309.639C269.858 309.424 269.928 309.209 270.138 309.065C270.909 309.137 271.399 309.065 272.029 308.85Z' fill='#5BD3DD' />
                <path d='M296.05 326.573C296.19 326.43 296.33 326.286 296.47 326.143C297.17 325.425 297.94 324.923 298.781 324.492C299.621 324.133 300.531 323.918 301.442 323.775C301.652 323.775 301.862 323.775 302.002 323.703C302.422 323.559 302.842 323.488 303.263 323.344C303.473 323.272 303.683 323.201 303.963 323.129C303.403 323.559 302.702 323.99 302.072 324.42L302.002 324.42C301.652 324.636 301.302 324.923 300.952 325.281C300.531 325.712 300.111 326.143 299.761 326.645C299.271 327.434 298.851 328.223 298.641 329.156C298.711 328.941 298.711 328.797 298.711 328.797C298.711 328.797 298.641 328.941 298.641 329.156C298.501 329.802 298.291 331.093 298.501 332.529C298.501 332.672 298.501 332.744 298.501 332.887C298.851 335.399 299.761 337.623 301.302 339.632C301.862 340.35 302.492 340.995 303.263 341.498C303.473 341.641 303.683 341.713 303.893 341.856C303.963 341.856 303.963 341.928 304.033 341.928C304.103 342 304.173 342 304.313 342C305.574 342.43 306.414 342.717 307.044 342.646C306.834 342.717 306.554 342.789 306.274 342.861C305.854 342.933 305.434 343.004 305.013 343.148C304.243 343.363 303.473 343.578 302.772 343.794C302.352 343.937 301.932 343.937 301.512 343.937C300.602 343.865 299.831 343.578 299.061 343.076C298.01 342.43 297.24 341.498 296.54 340.493C295.769 339.345 295.209 338.125 294.789 336.834C294.229 335.04 293.949 333.174 294.089 331.237C294.089 331.165 294.089 331.093 294.089 331.022C294.229 328.869 295.839 326.86 296.05 326.573Z' fill='url(#paint7_linear_290_1524)' />
            </g>
            <path d='M295.576 75.4023C295.576 77.9982 293.467 80.1134 290.879 80.1134C288.291 80.1134 286.183 77.9982 286.183 75.4023C286.279 72.8064 288.291 70.6912 290.879 70.6912C293.467 70.6912 295.576 72.8064 295.576 75.4023Z' fill='#5BD3DD' />
            <path d='M55.1192 339.226C55.1192 341.822 53.0106 343.937 50.4227 343.937C47.8349 343.937 45.7263 341.822 45.7263 339.226C45.8222 336.63 47.8349 334.515 50.4227 334.515C53.0106 334.515 55.1192 336.63 55.1192 339.226Z' fill='#5BD3DD' />
            <path d='M86.1155 333.102C86.1155 334.919 84.6395 336.399 82.828 336.399C81.0165 336.399 79.5405 334.919 79.5405 333.102C79.6076 331.284 81.0165 329.804 82.828 329.804C84.6395 329.804 86.1155 331.284 86.1155 333.102Z' fill='#D0423E' />
            <path d='M71.0869 22.1664C71.0869 23.9836 69.6109 25.4642 67.7995 25.4642C65.988 25.4642 64.512 23.9836 64.512 22.1664C64.5791 20.3493 65.988 18.8687 67.7995 18.8687C69.6109 18.8687 71.0869 20.3493 71.0869 22.1664Z' fill='#CC4403' />
            <path d='M246.158 331.158C246.043 331.001 245.928 330.843 245.813 330.686C245.506 330.332 245.159 330.055 244.774 329.661C243.966 328.951 243.234 328.28 242.465 327.492C241.851 326.784 241.236 326.075 240.701 325.212C240.241 324.584 240.015 323.88 239.79 323.176C239.649 321.733 239.668 319.786 240.235 317.533C240.196 317.611 240.233 317.728 240.31 317.768C240.386 318.003 240.538 318.277 240.653 318.434C241.377 319.884 242.413 321.103 243.567 322.285C244.221 322.916 244.952 323.587 245.606 324.218C245.991 324.612 246.375 325.006 246.72 325.477C246.988 325.909 247.255 326.34 247.366 326.887C247.317 327.938 246.877 329.18 246.158 331.158Z' fill='url(#paint8_linear_290_1524)' />
            <path d='M247.142 336.86C246.259 339.538 244.758 341.898 242.716 343.978C241.931 344.749 241.108 345.402 240.092 345.857C239.585 346.085 239.117 346.236 238.651 346.191C238.573 346.151 238.456 346.189 238.379 346.149C238.516 344.163 238.147 342.21 238.089 340.222C238.789 340.192 239.453 339.849 240.04 339.466C240.744 339.045 241.294 338.545 241.843 338.045C243.766 336.197 245.111 333.952 246.068 331.508C246.108 331.431 246.148 331.353 246.11 331.236C246.83 329.257 247.23 328.093 247.32 326.964C247.395 327.198 247.393 327.393 247.431 327.51C247.464 328.017 247.498 328.524 247.415 329.069C247.371 329.536 247.328 330.003 247.361 330.51C247.435 330.94 247.391 331.407 247.465 331.836C247.5 332.149 247.536 332.461 247.611 332.696C247.758 333.555 247.828 334.374 247.586 335.228C247.425 335.733 247.342 336.278 247.142 336.86Z' fill='url(#paint9_linear_290_1524)' />
            <path d='M105.582 40.1315C105.545 36.0559 106.502 32.127 108.328 28.3262C109.043 26.9055 109.865 25.6276 111.04 24.5292C111.628 23.98 112.198 23.5553 112.855 23.3979C112.979 23.4162 113.121 23.3101 113.245 23.3284C113.979 26.1077 115.387 28.6052 116.387 31.2967C115.446 31.6664 114.697 32.4463 114.073 33.2445C113.306 34.1489 112.788 35.0899 112.269 36.031C110.499 39.4582 109.703 43.1565 109.527 46.9464C109.509 47.0709 109.49 47.1954 109.596 47.3382C109.53 50.3811 109.523 52.1608 109.924 53.7463C109.712 53.4607 109.625 53.1934 109.519 53.0506C109.238 52.3731 108.957 51.6957 108.818 50.912C108.662 50.2529 108.505 49.5938 108.224 48.9163C107.924 48.3634 107.767 47.7042 107.468 47.1513C107.275 46.7411 107.081 46.331 106.87 46.0454C106.271 44.9394 105.797 43.8519 105.731 42.5702C105.717 41.8049 105.578 41.0213 105.582 40.1315Z' fill='url(#paint10_linear_290_1524)' />
            <defs>
                <linearGradient id='paint0_linear_290_1524' x1='18.4843' y1='29.9117' x2='34.9638' y2='30.2678' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#22A5AF' />
                    <stop offset='0.9916' stopColor='#47C3CD' />
                </linearGradient>
                <linearGradient id='paint1_linear_290_1524' x1='6.80698' y1='21.6241' x2='28.7536' y2='22.0983' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#188487' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint2_linear_290_1524' x1='42.8013' y1='84.6246' x2='34.6327' y2='91.7689' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5BD3DD' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint3_linear_290_1524' x1='287.225' y1='38.9315' x2='297.898' y2='37.0434' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5BD3DD' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint4_linear_290_1524' x1='262.873' y1='357.622' x2='272.273' y2='363.032' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5BD3DD' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint5_linear_290_1524' x1='305.714' y1='325.415' x2='310.5' y2='326.698' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#188487' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint6_linear_290_1524' x1='306.515' y1='340.391' x2='302.668' y2='333.444' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#22A5AF' />
                    <stop offset='0.9916' stopColor='#47C3CD' />
                </linearGradient>
                <linearGradient id='paint7_linear_290_1524' x1='303.517' y1='335.392' x2='299.624' y2='331.382' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5BD3DD' />
                    <stop offset='0.9916' stopColor='#22A5AF' />
                </linearGradient>
                <linearGradient id='paint8_linear_290_1524' x1='242.165' y1='322.708' x2='249.029' y2='334.271' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#CE3C3C' />
                    <stop offset='1' stopColor='#8E2626' />
                </linearGradient>
                <linearGradient id='paint9_linear_290_1524' x1='240.474' y1='345.008' x2='248.43' y2='321.01' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#CE3C3C' />
                    <stop offset='1' stopColor='#E87A4F' />
                </linearGradient>
                <linearGradient id='paint10_linear_290_1524' x1='110.912' y1='25.8693' x2='111.267' y2='62.4051' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#CE3C3C' />
                    <stop offset='1' stopColor='#E87A4F' />
                </linearGradient>
                <clipPath id='clip0_290_1524'>
                    <rect width='33.298' height='53.16' fill='white' transform='translate(0 17.0642) rotate(-30)' />
                </clipPath>
                <clipPath id='clip1_290_1524'>
                    <rect width='35.8047' height='51.8225' fill='white' transform='translate(320 308.133) rotate(90)' />
                </clipPath>
            </defs>
        </svg>

    )
})