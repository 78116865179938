import { makeAutoObservable } from 'mobx'
import { clubApi } from 'src/api'


class ClubStore {

    __clubs = []
    __selectedClubs = []

    __error = null
    __loading = false

    constructor() {
        makeAutoObservable(this)
    }

    *getAllClubs() {
        this.__loading = true
        const response = yield clubApi.getAllClubs()

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__clubs = response.clubs
    }

    *getSelectedClubs() {
        this.__loading = true
        const response = yield clubApi.getSelectedClubs()

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__selectedClubs = response.clubs
    }

    *setSelectedClubs(clubs) {
        this.__loading = true
        const response = yield clubApi.setSelectedClubs(clubs)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__selectedClubs = response.clubs
    }

    *createOneClub(clubName) {
        this.__loading = true
        const response = yield clubApi.createOneClub(clubName)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__clubs = [...this.__clubs, response.club]
    }

    get loading() {
        return this.__loading
    }

    get clubs() {
        return this.__clubs
    }

    get selectedClubs() {
        return this.__selectedClubs
    }

    get error() {
        return this.__error
    }

    setError(error) {
        this.__error = error
    }

    // error handler //

    handleError(message) {
        this.__error = message
        this.__loading = false
    }

    // response handler //

    handleSuccess() {
        this.__loading = false
        this.__error = null
    }

}

export default ClubStore