import { authApi } from './config'

export const authCheck = async () => {
    try {
        const response = await authApi.get('/auth')
        return response.data
    } catch (err) {
        return err.response.data
    }

}

export const login = async (user) => {
    try {
        const response = await authApi.post('/auth/login', user)
        return response.data
    } catch (err) {
        return err.response.data
    }
    
}

export const logout = async () => {
    try {
        const response = await authApi.get('/auth/logout')
        return response.data
    } catch (err) {
        return err.response.data
    }
}
