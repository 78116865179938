import React, { useContext, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { Block } from 'src/components'

import './style.scss'
// import ReCAPTCHA from 'react-google-recaptcha'
// import { PUBLIC_RECAPTCHA_KEY, production } from 'common/http/config'

export const Login = observer(() => {
    const { authStore } = useContext(AppContext)
    const recaptchaRef = useRef(null)

    const production = false

    const formik = useFormik({
        initialValues: { login: '', password: '' },
        validationSchema: Yup.object({
            login: Yup.string().trim().required().min(4).max(20),
            password: Yup.string().trim().required().min(6).max(20),
        }),
        onSubmit: async (values, actions) => {
            const token = production 
                ? await recaptchaRef.current.executeAsync() 
                : '123'
            production && recaptchaRef.current.reset()

            const user = { login: values.login, password: values.password, token }
            authStore.login(user)
            actions.resetForm()
        },
    })

    return (
        <Block w={400}>
            <form className='login' onSubmit={formik.handleSubmit}>

                {/* {production &&
                    <ReCAPTCHA
                        sitekey={PUBLIC_RECAPTCHA_KEY}
                        size='invisible'
                        ref={recaptchaRef}
                    />} */}
                
                <h2 className='title title--sm'>
                    Авторизация

                    {(authStore.error !== 'Не авторизован') && 
                        <>
                            <br />
                            <label className='label label--error'>{authStore.error}</label>
                        </>}
                </h2>

                <div className='login__form-container'>
                    <fieldset className='fieldset'>
                        <label className='label label--req'>Логин</label>
                        <input
                            className='input'
                            error={formik.errors.login && formik.touched.login}
                            type='text' placeholder='Введите логин' name='login' id='login'
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            value={formik.values.login} //disabled={loading}
                        />
                    </fieldset>

                    <fieldset className='fieldset'>
                        <label className='label label--req'>Пароль</label>
                        <input
                            className='input'
                            error={formik.errors.password && formik.touched.password}
                            type='password' placeholder='Введите пароль' name='password' id='password'
                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                            value={formik.values.password} //disabled={loading}
                        />
                    </fieldset>
                </div>

                <button className='btn' type='submit'>Войти</button>
            </form>
        </Block>
    )
}) 
