import { ButtonIcon } from 'src/components'
import './style.scss'

export const Block = ({ children, w, onClose }) => {
    const blockClasses = 'block '
        + (w ? ` block--${w} ` : '')
        + (onClose ? ' block--close-btn ' : '')

    return (
        <div className={blockClasses}>
            {onClose && <ButtonIcon onClick={onClose} classes='block__close-btn' icon='close' />}
            {children}
        </div>
    )
}
