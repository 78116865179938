import { HelpBar, PrizeView } from 'src/components'
import { Wheel } from './Wheel'
import { WheelFigures } from './WheelFigures'
import { WheelConfetti } from './WheelConfetti'
// import { gradientImage } from './images'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'


export const WheelContainer = observer(({ openForm }) => {
    const { wheelStore } = useContext(AppContext)
    const {
        title,
        text,
        showHelpBar,
        showFormBtn,
        showPrizeImage,
        showSpinBtn,
        spinning,
        finished,
    } = wheelStore.state
    const startSpin = () => wheelStore.startSpin()
    
    const [showConfetti, setShowConfetti] = useState(false)

    useEffect(() => {
        if (spinning) {
            setTimeout(() => {
                setShowConfetti(true)
            }, 3150)
        }
    }, [spinning])

    const showFigures = !finished

    const btnText = 
        showSpinBtn ? 'Начать' : 
            showFormBtn ? 'Забрать подарок' : 'Что же там?'

    const btnHandleClick =
        showSpinBtn ? startSpin :
            showFormBtn ? openForm : () => {}

    
    const isFormBtnDisabled = ['1 месяц фитнеса', 'Мерч DDX'].includes(wheelStore?.prize?.name) && btnText !== 'Начать'
        
    const prizeDateArray = wheelStore.prize?.date.replace(/[TZ]/g, ' ').trim().split(' ')
    const prizeDate = prizeDateArray && prizeDateArray[0]?.split('-').reverse().join('.')
    const prizeTime = prizeDateArray && prizeDateArray[1]?.split('.')[0]
    const formattedPrizeDate = prizeDateArray && [prizeDate, prizeTime].join(' ')

    const hideWheel = wheelStore.isPrizeReceived
    
    const isBtnDisabled = (!showSpinBtn && !showFormBtn) || isFormBtnDisabled

    return (
        <div className='wheel-component'>
            <div className='wheel-component__header'>
                <h2 key={'title_' + finished} className='title' dangerouslySetInnerHTML={title}></h2>
                <p key={'text ' + spinning} className='text' dangerouslySetInnerHTML={text}></p>

                {showHelpBar &&
                    <HelpBar />}

                <button
                    disabled={isBtnDisabled}
                    onClick={btnHandleClick}
                    className='wheel-component__btn btn'
                >
                    {btnText}
                </button>

                {showFormBtn && 
                    <button
                        disabled={isFormBtnDisabled}
                        onClick={openForm}
                        className='wheel-component__btn-mobile btn'
                    >
                        {btnText}
                    </button>}
            </div>

            <div className='wheel-container'>
                <div className='wheel-container__gradient'></div>

                {showPrizeImage &&
                    <PrizeView
                        prizeId={wheelStore.prize?.name}
                        date={formattedPrizeDate}
                    />}

                {showFigures 
                    && <WheelFigures />}

                <Wheel hide={hideWheel} />

                {showConfetti &&
                    <WheelConfetti />}

            </div>
        </div>
    )
})