export const SelectOption = ({ children, active, disabled, ...props }) => {
    const optionClasses = 'select__option'
        + (active ? ' select__option--active' : '')
        + (disabled ? ' select__option--disabled' : '')
        
    return (
        <div
            {...props}
            className={optionClasses}
        >
            {children}
        </div>
    )
}
