import React, { useContext } from 'react'
import { AppContext } from 'src'
import { Block } from 'src/components'

export const DeleteClub = ({ item, handleModal }) => {
    const { clubStore } = useContext(AppContext)

    const handleSubmit = () => {
        clubStore.deleteOneClub(item.id)
        handleModal(null)
    }

    return (
        <Block w={400} onClose={() => handleModal(null)}>
            <div className='edit-clubs'>
                <h2 className='title title--sm'>Удаление клуба <span>(Функция недоступна, для удаления обратитесь к администратору)</span></h2>

                <label className='label'>Вы действительно хотите удалить клуб <b>{item.name}</b>?</label>

                <div className='edit-clubs__btns'>
                    <button
                        className='btn' 
                        disabled
                        onClick={handleSubmit}
                    >
                        Подтвердить
                    </button>

                    <button
                        className='btn btn--light'
                        onClick={_ => handleModal()}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </Block>
    )
}
