import { useEffect, useRef, useState } from 'react'
import './style.scss'
import { SelectArrow } from './SelectArrow'
import { SelectList } from './SelectList'

export const Select = ({
    formik,
    search,
    placeholder,
    options = [],
    value,
    onChange,
    onBlur,
    error,
    disabled,
    ...props
}) => {
    const [isOpened, setIsOpened] = useState(false)
    const rootElement = useRef(null)
    const inputElement = useRef(null)

    const [searchString, setSearchString] = useState('')
    const handleSearchString = (event) => setSearchString(event.target.value)

    const filteredList = search 
        ? options.filter(item =>
            item.name.toLowerCase().includes(searchString.toLowerCase().trim())
        )
        : options

    const selectClasses = 'select'
        + (isOpened ? ' select--active' : '')
        + (value?.id ? ' select--selected' : '')
        + (search ? ' select--search' : '')
        + (error ? ' select--error' : '')
        + (disabled ? ' select--disabled' : '')

    useEffect(() => {
        const onClick = e => rootElement.current.contains(e.target) || toggleSelect()
        if (isOpened) document.addEventListener('click', onClick)
        return () => { isOpened && document.removeEventListener('click', onClick) }
    }, [isOpened])

    const handleSelect = (item) => {
        if (searchString.length) {
            setSearchString('')
        }

        onChange(item)
        toggleSelect(item)
    }

    const toggleSelect = (item) => {
        if (isOpened) inputElement.current.blur()
        if (isOpened && formik && !item) {
            onBlur({ target: { value, name: props.name, id: props.id } })
        }
        setIsOpened(prev => !prev)
    }

    const inputValue = search
        ? searchString
        : value?.name || ''

    return (
        <div className={selectClasses} ref={rootElement}>
            {search &&
                <svg className='search-icon' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='11' cy='11' r='7' stroke='#AFAFE8' strokeWidth='2' />
                    <path d='M20 20L17 17' stroke='#AFAFE8' strokeWidth='2' strokeLinecap='round' />
                </svg>}

            <input
                type='text'
                ref={inputElement}
                value={inputValue}
                onClick={toggleSelect}
                onChange={handleSearchString}
                readOnly={!search}
                placeholder={placeholder}
                // onBlur={onBlur}
            />

            <SelectArrow
                isOpened={isOpened}
            />

            {isOpened &&
                <div className='select__container'>
                    <SelectList
                        options={filteredList}
                        value={value}
                        handleSelect={handleSelect}
                    />
                </div>
            }
        </div>
    )
}