import { ButtonIcon } from 'src/components'
import React from 'react'

export const ClubItem = ({ item, onEdit, onDelete, onAdd }) => {
    return (
        <div className='club-list__item'>
            {onAdd && <ButtonIcon sm icon='add' onClick={() => onAdd(item)} />}
            {onDelete && <ButtonIcon sm icon='close' onClick={() => onDelete(item)} />}
            {onEdit && <ButtonIcon sm icon='edit' onClick={() => onEdit(item)} />}
            {item.name}
        </div>
    )
}
