import { useContext, useEffect, useState } from 'react'
import { Form } from './components/Form'
import { Wheel } from './components/Wheel'
import { AppContext } from 'src'
import { useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { detectIncognito } from 'detectincognitojs'
import { Loader } from 'src/components'

export const Main = observer(() => {
    const { wheelStore, clubStore } = useContext(AppContext)
    const [params] = useSearchParams()

    const [showForm, setShowForm] = useState(null)
    const openForm = () => setShowForm(true)

    useEffect(() => {
        const clubId = params.get('club') || 0

        detectIncognito().then((result) => {
            // console.log(result.browserName, result.isPrivate) // DELETE LINE

            const id = result.isPrivate
                ? 0
                : clubId

            wheelStore.getPrize(id)
                .then(() => {
                    if (!wheelStore.prize) {
                        clubStore.getSelectedClubs()
                    }
                    setShowForm(wheelStore.prize ? false : true)
                })
        })

    }, []) // eslint-disable-line

    if (!wheelStore.loaded || showForm === null) return <div></div>

    const isLoading = clubStore.loading

    return (
        <div className='container'>
            {isLoading &&
                <div className='overlay'>
                    <Loader />
                </div>}

            {showForm
                ? <Form />
                : <Wheel openForm={openForm} />}
        </div>
    )
})