import { Block, SocialIcon } from 'src/components'

export const PhoneAlreadyUsed = ({ onClose }) => {
    return (
        <Block w={500} onClose={() => onClose(null)}>
            <div className='form form--t-left'>
                <h2 className='title title--sm'>Указанный номер телефона уже зарегистрирован</h2>

                <label className='label'>Следите за анонсами наших мероприятий в социальных сетях 😉</label>

                <div style={{ display: 'flex', gap: 10 }}>
                    <a className='link' target='_blank' rel='noreferrer' href='https://vk.com/ddxfitness'>
                        <button className='btn btn--social'>
                            <SocialIcon icon='vk' />
                        </button>
                    </a>

                    <a className='link' target='_blank' rel='noreferrer' href='https://t.me/ddxfitness_official'>
                        <button className='btn btn--social'>
                            <SocialIcon icon='tg' />
                        </button>
                    </a>

                    <a className='link' target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg'>
                        <button className='btn btn--social'>
                            <SocialIcon icon='yt' />
                        </button>
                    </a>

                    <a className='link' target='_blank' rel='noreferrer' href='https://dzen.ru/ddx_fitness_ru'>
                        <button className='btn btn--social'>
                            <SocialIcon icon='dzen' />
                        </button>
                    </a>
                </div>
            </div>
        </Block>
    )
}