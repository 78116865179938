import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { AddPrizes } from './AddPrizes'
import './style.scss'
import { ResetOnePrize } from './ResetOnePrize'
import { ResetPrizes } from './ResetPrizes'
import { useNavigate } from 'react-router-dom'
import { AppContext } from 'src'
import { Block, ButtonIcon, Select } from 'src/components'

const promotionStatuses = [
    { id: 1, name: 'Активен', value: true },
    { id: 2, name: 'Выключен', value: false },
]

export const EditLottery = observer(({ handleModal }) => {
    const { wheelStore, clubStore } = useContext(AppContext)

    const [statusOld, setStatusOld] = useState(null)
    const [status, setStatus] = useState(null)
    
    const [currentClub, setCurrentClub] = useState(null)
    const navigate = useNavigate()

    const canSubmit = statusOld?.value !== status?.value

    useEffect(() => {
        if (currentClub?.id) {
            if (currentClub.active) {
                setStatus(promotionStatuses[0])
                setStatusOld(promotionStatuses[0])
            } else {
                setStatus(promotionStatuses[1])
                setStatusOld(promotionStatuses[1])
            }
        }
    }, [currentClub?.id, clubStore.selectedClubs])

    useEffect(() => {
        if (clubStore.selectedClubs?.length && !currentClub?.id) {
            setCurrentClub(clubStore.selectedClubs[0])
        }
    }, [clubStore.selectedClubs])

    useEffect(() => {
        if (currentClub) {
            wheelStore.getPrizesByClubId(currentClub.id)
            // promotionStore.getPromotionStatus(currentClub.id)
        }
    }, [currentClub, currentClub?.id])

    const closeTurnOffAndResetPrizes = async () => {
        await wheelStore.setClubInActiveById(currentClub.id, true)
        await clubStore.getSelectedClubs()
        const updatedClub = clubStore.selectedClubs.find(c => c.id === currentClub.id)
        setCurrentClub({ ...currentClub, active: updatedClub.active })

        if (updatedClub.active) {
            setStatus(promotionStatuses[0])
            setStatusOld(promotionStatuses[0])
        } else {
            setStatus(promotionStatuses[1])
            setStatusOld(promotionStatuses[1])
        }

        handleModal(null)
    }

    const closeTurnOffAndNotResetPrizes = async () => {
        await wheelStore.setClubInActiveById(currentClub.id, false)
        await clubStore.getSelectedClubs()
        const updatedClub = clubStore.selectedClubs.find(c => c.id === currentClub.id)
        setCurrentClub({ ...currentClub, active: updatedClub.active })

        if (updatedClub.active) {
            setStatus(promotionStatuses[0])
            setStatusOld(promotionStatuses[0])
        } else {
            setStatus(promotionStatuses[1])
            setStatusOld(promotionStatuses[1])
        }

        handleModal(null)
    }

    const closeAndNotResetPrizes = () => {
        handleModal(null)
    }
    
    const closeAndResetPrizes = () => {
        wheelStore.resetAllPrizeQuantities(currentClub.id)
        handleModal(null)
    }
    
    const handleStatus = async () => {
        if (status?.value) {
            await wheelStore.setClubActiveById(currentClub.id)
            await clubStore.getSelectedClubs()

            const updatedClub = clubStore.selectedClubs.find(c => c.id === currentClub.id)
            setCurrentClub({ ...currentClub, active: updatedClub.active })

            if (updatedClub.active) {
                setStatus(promotionStatuses[0])
                setStatusOld(promotionStatuses[0])
            } else {
                setStatus(promotionStatuses[1])
                setStatusOld(promotionStatuses[1])
            }
            
        } else {
            handleModal(<ResetPrizes clubId={currentClub.id} onSubmit={closeTurnOffAndResetPrizes} onClose={closeTurnOffAndNotResetPrizes} text='Вы хотите сбросить призы?' />)
        }
    }

    const isSelectedClubs = clubStore.selectedClubs?.length > 0
    const handleNavigateToClubSelect = () => navigate('/admin/select')

    const currentClubLink = currentClub
        ? 'https://play-ddx.ru/?club=' + currentClub.id
        : null
    
    const qrCodeLink = currentClubLink
        ? 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + currentClubLink
        : null

    const isSelected = (club) => club.id === currentClub?.id
    const selectedClubsList = isSelectedClubs && clubStore.selectedClubs.map(club => {
        return <div
            key={club.id}
            className={`tables__item ${isSelected(club) ? 'tables__item--selected' : ''}`}
            onClick={() => setCurrentClub(club)}
        >
            {club.name}
        </div>
    })

    return (
        <Block w={500}>
            <div className='edit-lottery'>
                <h2 className='title title--sm'>Настройка розыгрыша</h2>

                {isSelectedClubs ? <>

                <div className='tables'>
                    {selectedClubsList}
                </div>

                <fieldset className='fieldset'>
                    <label className='label'>Статус розыгрыша: </label>
                    <Select
                        value={status}
                        options={promotionStatuses}
                        onChange={setStatus}
                    />
                </fieldset>

                <button className='btn' onClick={handleStatus} disabled={!canSubmit}>Применить</button>

                {currentClubLink &&
                    <fieldset className='fieldset' style={{ alignItems: 'center' }}>
                        <a className='link' href={currentClubLink} target='_blank' rel='noreferrer'>{currentClubLink}</a>
                        <a className='link' href={qrCodeLink} target='_blank' rel='noreferrer'>
                            <img width={150} src={qrCodeLink} alt='qr-code' />
                        </a>
                    </fieldset>}
                
                <fieldset className='fieldset'>
                    <label className='label'>Список призов:</label>
                    <div className='prize-list'>
                        {[...wheelStore.prizes].sort((a, b) => a.prize.name.localeCompare(b.prize.name)).map((item) => {
                            return <div key={item.id + new Date()} className='prize-list__item'>
                                <ButtonIcon
                                    icon='add' 
                                    onClick={_ => handleModal(<AddPrizes handleModal={() => handleModal(null)} item={item} />)} 
                                />
                                {/* <ButtonIcon disabled icon='edit' /> */}
                                <div className='prize-list__item-name'>{item.prize.name}</div>
                                <div className='prize-list__item-quantity'>{item.quantity}</div>
                                <ButtonIcon
                                    onClick={_ => handleModal(<ResetOnePrize onClose={handleModal} item={item} />)}
                                    icon='close'
                                    disabled={item.quantity <= 0}
                                />
                            </div>
                        })}
                    </div>
                </fieldset>

                <button className='btn btn--light' 
                    onClick={() => handleModal(<ResetPrizes clubId={currentClub.id} onSubmit={closeAndResetPrizes} onClose={closeAndNotResetPrizes} />)}
                >
                    Сбросить призы
                </button></> 
                :
                <>
                    <label className='label'>Не выбран ни один клуб</label>
                    <button className='btn' onClick={handleNavigateToClubSelect}>Выбрать клуб</button>
                </>}

            </div>
        </Block>
    )
})
