import { NavLink } from 'react-router-dom'

export const DesktopNavigation = ({ active, handleLogout }) => {
    return (
        <>
            <nav className='nav'>
                <NavLink className={active} to='/admin/select'>Выбор клубов</NavLink>
                <NavLink className={active} to='/admin/edit'>Редактирование</NavLink>
                <NavLink className={active} to='/admin/lottery'>Адская рулетка</NavLink>
            </nav>
            <button onClick={handleLogout} className='nav__item nav__item--logout'>Выйти</button>
        </>
    )
}