import React, { useContext, useState } from 'react'
import { Form } from './Form'
import { PrizeView } from 'src/components'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { Thanks } from './Thanks'
import { PhoneAlreadyUsed } from './PhoneAlreadyUsed'

export const FormContainer = observer(() => {
    const { wheelStore, clubStore } = useContext(AppContext)

    const [isFormSent, setFormSent] = useState(false)
    const handleReturnToForm = () => {
        wheelStore.deletePrize()
        clubStore.getSelectedClubs()
        setFormSent(false)
    }
    const hideForm = () => setFormSent(true)

    const [isErrorModal, setErorrModal] = useState(false)
    const showErrorModal = () => setErorrModal(true)
    const hideErrorModal = () => setErorrModal(false)


    const prize = wheelStore.prize?.name || 'no-prize'

    return (
        <div className='form-component'>
            {isErrorModal &&
                <div className='overlay container'>
                    <PhoneAlreadyUsed onClose={hideErrorModal} />
                </div>}

            {isFormSent
                ? <Thanks handleReturnToForm={handleReturnToForm} />
                : <Form
                    handleThanksBlock={hideForm}
                    handleErrorModal={showErrorModal}
                    />}
            
            <PrizeView prizeId={prize} />
        </div>
    )
})