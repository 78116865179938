import { makeAutoObservable } from 'mobx'
import { yandexMetrika } from 'src/analytics'
import { leadApi, prizeApi } from 'src/api'

const beforeSpinState = 'before-spin'
const spinningState = 'spinning'
const afterSpinState = 'after-spin'
const showPrizeState = 'show-prize'

const STATES = {

    [beforeSpinState]: {
        id: 0,
        showHelpBar: true,
        showFormBtn: false,
        showPrizeImage: false,
        showSpinBtn: true,
        hideWheel: false,
        title: { __html: 'Крути колесо<br/><span>и получи бонус!</span>' },
        text: { __html: 'Ну что, начнем?<br/> У тебя всего одна попытка! Удачи!' },
        spinning: false,
        finished: false,
    },

    [spinningState]: { 
        id: 1,
        showHelpBar: false,
        showFormBtn: false,
        showPrizeImage: false,
        showSpinBtn: false,
        hideWheel: false,
        title: { __html: 'Крути колесо<br/><span>и получи бонус!</span>' },
        text: { __html: 'Итак, ну что же так? <br/> Раз... Два... Три...' },
        spinning: true,
        finished: false,
    },

    [afterSpinState]: { 
        id: 2,
        showHelpBar: false,
        showFormBtn: false,
        showPrizeImage: false,
        showSpinBtn: false,
        hideWheel: false,
        title: { __html: 'Урааааа!<br/><span>Поздравляем!</span>' },
        text: { __html: 'Покажи скрин экрана на стойке регистрации и получи свой приз.' },
        spinning: false,
        finished: true,
    },

    [showPrizeState]: {
        id: 3,
        showHelpBar: false,
        showFormBtn: true,
        showPrizeImage: true,
        showSpinBtn: false,
        hideWheel: true,
        title: { __html: 'Урааааа!<br/><span>Поздравляем!</span>' },
        text: { __html: 'Покажи скрин экрана на стойке регистрации и получи свой приз.' },
        spinning: false,
        finished: true,
    }
}

const showPrizeTextAndButton = {
    '-1500р': { 
        text: 'Покажи скрин экрана на стойке регистрации или заполни анкету, чтобы получить свой приз..',
        showFormBtn: true
    },
    '-2000р': { 
        text: 'Покажи скрин экрана на стойке регистрации или заполни анкету, чтобы получить свой приз..',
        showFormBtn: true
    },
    '-3000р': { 
        text: 'Покажи скрин экрана на стойке регистрации или заполни анкету, чтобы получить свой приз..',
        showFormBtn: true
    },
    '1 месяц фитнеса': { 
        text: 'Покажи скрин экрана на стойке регистрации и получи свой приз.',
        showFormBtn: false
    },
    'Мерч DDX': { 
        text: 'Покажи скрин экрана на стойке регистрации и получи свой приз.',
        showFormBtn: false
    },
}

class WheelStore {

    __loaded = false
    __loading = false
    __error = null

    __prize = null
    __club = null
    __state = null
    __initial_state = null
    __timeout = null

    __prizes = []

    constructor() {
        makeAutoObservable(this)
    }

    *getPrize(clubId) {
        const response = yield prizeApi.getPrize(clubId)

        if (response?.status !== 'success') {
            this.__loaded = true
            return
        }

        // console.log(response.prize) // DELETE LINE

        this.__prize = response.prize
        this.__club = response.club

        if (response.prize.received) {
            
            this.__state = showPrizeState
            this.__initial_state = showPrizeState
        } else {
            yandexMetrika.wheelAppear()
            this.__state = beforeSpinState
            this.__initial_state = beforeSpinState
        }

        this.__loaded = true
    }

    *setPrizeReceived () {
        const response = yield prizeApi.setPrizeReceived()
        
        // console.log(response) // DELETE LINE

        if (response?.status !== 'success') {
            return
        }

        yandexMetrika.prizeAppear()
    }

    *createLead(data) {
        const response = yield leadApi.createLead(data)

        // console.log(response) // DELETE LINE
        return response
    }

    deletePrize() {
        this.__prize = null
        this.__club = null
    }

    get isPrizeReceived() {
        return this.__initial_state === showPrizeState
    }

    get prize() {
        return this.__prize
    }

    get club() {
        return this.__club
    }

    get state() {
        if (!this.__state) {
            return {}
        }

        if ([afterSpinState, showPrizeState].includes(this.__state)) {
            return {
                ...STATES[this.__state],
                text: { __html: showPrizeTextAndButton[this.__prize.name].text },
                // showFormBtn: STATES[this.__state].showFormBtn && showPrizeTextAndButton[this.__prize.name].showFormBtn,
            }
        }

        return STATES[this.__state]
    }

    startSpin() {
        yandexMetrika.spinWheel()
        this.__state = spinningState
        this.__timeout = null
    }

    finishSpin() {
        this.__state = afterSpinState
        this.__timeout = setTimeout(() => {
            this.__state = showPrizeState
        }, 2000)
    }

    resetSpin() {
        this.__state = beforeSpinState
        this.__timeout = null
    }


    // ADMIN METHODS

    *getPrizesByClubId(clubId) {
        this.__loading = true
        const response = yield prizeApi.getPrizesByClubId(clubId)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = response.prizes
    }

    *setClubActiveById(clubId) {
        this.__loading = true
        const response = yield prizeApi.setClubActiveById(clubId, true)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = response.prizes
    }

    *setClubInActiveById(clubId, reset) {
        this.__loading = true
        const response = yield prizeApi.setClubActiveById(clubId, false, reset)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = response.prizes || []
    }

    *resetAllPrizeQuantities(clubId) {
        this.__loading = true
        const response = yield prizeApi.resetAllPrizeQuantities(clubId)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = response.prizes || []
    }

    *addPrizeQuantityById(prizeId, quantity) {
        this.__loading = true
        const response = yield prizeApi.addPrizeQuantityById(prizeId, quantity)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = [...this.__prizes].map((prize) => {
            if (prize.id === prizeId) {
                return { ...prize, ...response.prize }
            }
            return prize
        })
    }

    *resetPrizeQuantityById(prizeId) {
        this.__loading = true
        const response = yield prizeApi.resetPrizeQuantityById(prizeId)

        // console.log(response) // DELETE LINE

        if (response.status !== 'success') {
            this.handleError(response.message)
            return
        }

        this.handleSuccess()
        this.__prizes = [...this.__prizes].map((prize) => {
            if (prize.id === prizeId) {
                return { ...prize, quantity: 0 }
            }
            return prize
        })
    }

    get loaded() {
        return this.__loaded
    }

    get loading() {
        return this.__loading
    }

    get prizes() {
        return this.__prizes
    }

    get error() {
        return this.__error
    }

    setError(error) {
        this.__error = error
    }

    // error handler //

    handleError(message) {
        this.__error = message
        this.__loading = false
    }

    // response handler //

    handleSuccess() {
        this.__loading = false
        this.__error = null
    }
    

}

export default WheelStore