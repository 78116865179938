// import { Block, Form, Title, Label, Button } from 'common/components'
// import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { AppContext } from 'src'
import { Block } from 'src/components'

export const ResetOnePrize = observer(({ onClose, item }) => {
    const { wheelStore } = useContext(AppContext)

    const handleSubmit = () => {
        wheelStore.resetPrizeQuantityById(item.id)
        onClose(null)
    }

    return (
        <Block w={400} onClose={() => onClose(null)} >
            <div className='edit-lottery'>
                <h2 className='title title--sm'>
                    Сброс приза
                </h2>

                <label className='label'>Вы действительно хотите сбросить приз "{item.prize.name}"?</label>

                <div className='edit-lottery__btns'>
                    <button className='btn' onClick={handleSubmit}>Подтвердить</button>
                    <button className='btn btn--light' onClick={() => onClose(null)}>Отмена</button>
                </div>
            </div>
        </Block>
    )
})

