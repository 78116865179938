import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'

import 'src/styles/index.scss'
import 'src/index.scss'

import { App } from 'src/App'
import { AuthStore, ClubStore, WheelStore } from 'src/store'
import { BrowserRouter } from 'react-router-dom'

export const AppContext = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<BrowserRouter>
		<AppContext.Provider value={{
			wheelStore: new WheelStore(),
			clubStore: new ClubStore(),
			authStore: new AuthStore(),
		}}>
			<App />
		</AppContext.Provider>
	</BrowserRouter>
)