import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { Block, ClubList, Select } from 'src/components'
import './style.scss'

export const SelectClubs = observer(() => {
    const { clubStore } = useContext(AppContext)
    const [selectedClubs, setSelectedClubs] = useState([])
    useEffect(() => { setSelectedClubs(clubStore.selectedClubs) }, [clubStore.selectedClubs])

    const unselectedClubs = [...clubStore.clubs].filter(item =>
        !selectedClubs.filter(sel => sel.id === item.id).length
    )

    const canSubmit = 
        JSON.stringify([...selectedClubs].map(c => c.id).sort((a, b) => a - b)) !==
        JSON.stringify([...clubStore.selectedClubs].map(c => c.id).sort((a, b) => a - b))

    const handleSelect = (item) => {
        setSelectedClubs(prev => [...prev, item])
    }

    const handleUnselect = (item) => {
        setSelectedClubs(prev => prev.filter(s => s.id !== item.id))
    }

    const clearSelectedClubs = () => {
        setSelectedClubs([])
    }

    const handleSubmit = () => {
        const newSelectedClubsIds = selectedClubs.map(selectedClub => +selectedClub.id)
        clubStore.setSelectedClubs(newSelectedClubsIds)
    }

    return (
        <Block w={500}>
            <div className='select-clubs'>
                <h2 className='title title--sm'>Участие клубов</h2>

                <div className='select-clubs__fields'>
                    <fieldset className='fieldset'>
                        <label className='label'>Выберите клубы:</label>
                        <Select
                            search
                            placeholder='Поиск клубов...'
                            options={unselectedClubs}
                            value={{}}
                            onChange={handleSelect}
                        />
                    </fieldset>

                    <fieldset className='fieldset'>
                        <label className='label'>Выбраны клубы:</label>
                        <ClubList items={selectedClubs} onDelete={handleUnselect} notFound='Ничего не выбрано' />
                    </fieldset>
                </div>

                <div className='select-clubs__btns'>
                    <button
                        className='btn'
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                    >
                        Подтвердить
                    </button>

                    <button
                        className='btn btn--light'
                        onClick={clearSelectedClubs}
                    >
                        Сбросить&nbsp;все
                    </button>
                </div>
            </div>
        </Block>
    )
})

