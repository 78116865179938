import { Logo } from 'src/components'
import './style.scss'
import { NavLink, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { DesktopNavigation, MobileNavigation } from './components'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'


export const Header = observer(() => {
    const isDesktopWidth = useMediaPredicate('(min-width: 850px)')
    const { authStore } = useContext(AppContext)
    const { pathname } = useLocation()

    const isAuth = authStore.isAuth
    const isAdminPath = pathname.startsWith('/admin')
    const isMenuItems = isAuth && isAdminPath

    const [menu, setMenu] = useState(false)
    const closeMenu = () => setMenu(false)
    const toggleMenu = () => setMenu(prevState => !prevState)

    useEffect(() => {
        if (isDesktopWidth) {
            closeMenu()
        }
    }, [isDesktopWidth])

    const active = ({ isActive }) => isActive
        ? 'nav__item nav__item--active'
        : 'nav__item'

    const handleLogout = () => authStore.logout()

    return (
        <header id='start' className='header'>
            <div className='container'>
                <NavLink onClick={closeMenu} to='/'>
                    <Logo />
                </NavLink>

                {isMenuItems &&
                    <>
                        <DesktopNavigation
                            active={active}
                            handleLogout={handleLogout}
                        />

                        <MobileNavigation
                            toggleMenu={toggleMenu}
                            onClose={closeMenu}
                            active={active}
                            isMenu={menu}
                            handleLogout={handleLogout}
                        />
                    </>}
            </div>
        </header>
    )
})