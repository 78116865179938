export const disableWindowScroll = () => {
    document.querySelector('body').classList.add('disable-scroll')
}

export const enableWindowScroll = () => {
    document.querySelector('body').classList.remove('disable-scroll')
}

export const validatePhone = (val) => {
    const digits = val.replace(/[^\d]/g, '')
    return Boolean(digits.length === 11 
            && !digits.startsWith('77')
            && !digits.startsWith('70')
            && !digits.startsWith('71')
            && !digits.startsWith('72')
            && !digits.startsWith('76')
        )
}

export const emailREGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line