import './style.scss'

const icons = {
    close:
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path className='stroke-color' d='M18 6L6 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path className='stroke-color' d='M6 6L18 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>,
    edit:
        <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <path 
                className='fill-color' 
                d='M 14.671875 4.457031 C 14.136719 4.519531 13.644531 4.730469 13.125 5.136719 C 12.984375 5.246094 10.964844 7.242188 8.644531 9.570312 C 4.546875 13.671875 4.410156 13.8125 4.246094 14.15625 C 4.011719 14.652344 3.183594 17.945312 3.148438 18.519531 C 3.113281 19.238281 3.300781 19.753906 3.773438 20.230469 C 4.242188 20.703125 4.761719 20.886719 5.507812 20.84375 C 6.113281 20.8125 9.296875 20.011719 9.824219 19.757812 C 10.191406 19.585938 10.234375 19.542969 14.453125 15.332031 C 16.796875 13 18.804688 10.960938 18.921875 10.804688 C 19.167969 10.492188 19.410156 9.984375 19.496094 9.632812 C 19.570312 9.332031 19.570312 8.664062 19.496094 8.367188 C 19.414062 8.046875 19.257812 7.691406 19.050781 7.375 C 18.839844 7.054688 17.207031 5.382812 16.804688 5.078125 C 16.164062 4.589844 15.4375 4.378906 14.671875 4.457031 Z M 14.515625 9.476562 C 15.324219 10.285156 15.984375 10.964844 15.984375 10.992188 C 15.984375 11.054688 9.097656 17.929688 8.972656 17.996094 C 8.820312 18.066406 5.507812 18.890625 5.351562 18.890625 C 5.183594 18.890625 5.109375 18.816406 5.109375 18.648438 C 5.109375 18.492188 5.933594 15.179688 6.003906 15.027344 C 6.070312 14.902344 12.945312 8.015625 13.007812 8.015625 C 13.03125 8.015625 13.714844 8.675781 14.515625 9.476562 Z M 14.515625 9.476562 '
            />
        </svg>,
    add:
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path className='fill-color' fillRule='evenodd' clipRule='evenodd' d='M12 5C12.5523 5 13 5.44772 13 6L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 6C11 5.44772 11.4477 5 12 5Z'/>
            <path className='fill-color' fillRule='evenodd' clipRule='evenodd' d='M19 12C19 12.5523 18.5523 13 18 13L6 13C5.44771 13 5 12.5523 5 12C5 11.4477 5.44771 11 6 11L18 11C18.5523 11 19 11.4477 19 12Z'/>
        </svg>

}

export const ButtonIcon = ({ classes = '', icon = 'close', sm, disabled, ...props }) => {
    const buttonClasses = 'btn-icon ' 
        + (sm ? ' btn-icon--sm ' : '')
        + (disabled ? ' btn-icon--disabled ' : '')
        + ' btn-icon--' + icon + ' '
        + classes
    return <button {...props} disabled={disabled} className={buttonClasses}>{icons[icon]}</button>
}
