const MAX_SPIN = 7
// const MIN_SPIN = 4
const MIN_SPIN = 1

const MIN_OFFSET = 2 // 0
const MAX_OFFSET = 33 // 35

const uniqueItems = [
    { id: 1, name: '-2000р', deg: [1, 145, 289] },
    { id: 2, name: '-3000р', deg: [37, 181] },
    { id: 3, name: '-1500р', deg: [73, 217] },
    { id: 4, name: 'Мерч DDX', deg: [109, 253] },
    { id: 5, name: '1 месяц фитнеса', deg: [325] }
]

export const calculateSpin = (item, spins, minSpins = MIN_SPIN, maxSpins = MAX_SPIN, backward = false) => {
    // getting item number
    const current = uniqueItems.filter((f) => f.name === item.name)[0]
    // console.log(current)
    // const item = Math.floor(Math.random() * (uniqueItems.length))
    // console.log('current', current)

    // getting spin count
    const spinCount = spins || Math.floor(Math.random() * (maxSpins - minSpins + 1)) + minSpins

    // getting rotating offset
    const offset = Math.floor(Math.random() * (MAX_OFFSET - MIN_OFFSET + 1)) + MIN_OFFSET

    // getting item offset
    const offsetCount = current.deg.length
    const offsetNumber = Math.floor(Math.random() * (offsetCount))
    const itemOffset = backward
        ? current.deg[offsetNumber] + offset
        : 360 - current.deg[offsetNumber] - offset

    const multiplier = backward
        ? -1
        : 1
    // const itemOffset = 360 - current.deg[offsetNumber] - offset

    // const itemOffset = items[item].deg[offsetCount] + 17
    // console.log('\nItem: ', item, current, ' Spins: ', spins, ' Offset: ', offset, 
    //     ' globalOffset: ', itemOffset, ' Sum rotation deg: ', (spins * 360) + itemOffset)   

    return ((spinCount * 360) + itemOffset) * multiplier
    
}


export const isCoordinateInsideWheel = (sideWidth, x, y, outerOffset, innerOffset) => {
    const radius = sideWidth / 2

    const outerRadius = radius * (1 - (outerOffset / 100))
    const innerRadius = radius * (innerOffset / 100)

    const center = radius

    const distanceToCenter = Math.sqrt(Math.pow(x - center, 2) + Math.pow(y - center, 2))

    const isInsideOfOuterCircle = distanceToCenter <= outerRadius
    const isOutsideOfInnerCircle = distanceToCenter > innerRadius

    return isInsideOfOuterCircle && isOutsideOfInnerCircle
}

export const distanceFromCenter = (sideWidth, x, y, outerOffset, innerOffset) => {
    const radius = sideWidth / 2

    const outerRadius = radius * (1 - (outerOffset / 100))
    const innerRadius = radius * (innerOffset / 100)

    const center = radius

    const distanceToCenter = Math.sqrt(Math.pow(x - center, 2) + Math.pow(y - center, 2))

    const distanceToInnerOffcet = distanceToCenter - innerRadius
    const allAvailableDistance = outerRadius - innerOffset

    return 100 * (distanceToInnerOffcet / allAvailableDistance)
}

export const getCoefficientFromRadius = (sideWidth, outerOffset, innerOffset) => {
    const outerCircleLength = sideWidth * Math.PI
    const innerCircleLength = sideWidth * Math.PI

    return outerCircleLength / innerCircleLength
}

export const getAngleOfPoint = (sideWidth, x, y) => {
    const center = sideWidth / 2

    // Вычислите угол относительно центра круга
    const angleRadians = Math.atan2(y - center, x - center)
    const angleDegrees = (angleRadians * 180) / Math.PI

    return angleDegrees + 180
}

// isCoordinateInsideWheel
// distanceFromCenter * getCoefficientFromRadius
// getAngleOfPoint