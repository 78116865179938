const icons = {
    tg: 
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M22.38 2.1315L1.12065 10.2774C-0.330214 10.8564 -0.321821 11.6606 0.85446 12.0192L6.31259 13.7111L18.9411 5.79398C19.5383 5.43298 20.0838 5.62718 19.6354 6.02274L9.40378 15.198H9.40138L9.40378 15.1992L9.02727 20.7894C9.57884 20.7894 9.82225 20.538 10.1316 20.2414L12.7827 17.6798L18.2972 21.7271C19.314 22.2835 20.0443 21.9975 20.2973 20.7918L23.9172 3.84003C24.2877 2.36384 23.3501 1.69544 22.38 2.1315Z' fill='white'/>
        </svg>,
    vk: 
        <svg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M11.7426 13.4442H13.1781C13.1781 13.4442 13.6101 13.3986 13.8321 13.1736C14.0376 12.9643 14.0316 12.5756 14.0316 12.5756C14.0316 12.5756 14.0016 10.7472 14.8956 10.4781C15.7761 10.2118 16.9055 12.2438 18.1055 13.0241C19.0115 13.6165 19.7 13.4883 19.7 13.4883L22.9055 13.4456C22.9055 13.4456 24.5809 13.3459 23.786 12.0957C23.7215 11.9932 23.324 11.1701 21.404 9.4785C19.394 7.70712 19.661 7.99333 22.082 4.93044C23.5565 3.06508 24.146 1.92593 23.9615 1.43894C23.786 0.974736 22.7015 1.0972 22.7015 1.0972L19.0925 1.11855C19.0925 1.11855 18.8225 1.08296 18.6275 1.19545C18.4325 1.30509 18.308 1.56282 18.308 1.56282C18.308 1.56282 17.738 3.00527 16.973 4.23413C15.3681 6.8257 14.7231 6.96097 14.4621 6.80007C13.8516 6.42415 14.0046 5.29354 14.0046 4.49044C14.0046 1.98004 14.4051 0.933442 13.2246 0.662894C12.8301 0.573186 12.5421 0.51338 11.5386 0.503413C10.2486 0.489173 9.16115 0.507684 8.54465 0.793896C8.13366 0.984704 7.81716 1.41046 8.00916 1.43467C8.24916 1.46315 8.78915 1.57279 9.07415 1.94301C9.44614 2.42003 9.43414 3.49226 9.43414 3.49226C9.43414 3.49226 9.64414 6.44693 8.93915 6.81431C8.45016 7.06635 7.78416 6.5523 6.35018 4.20281C5.61519 2.99958 5.0632 1.66819 5.0632 1.66819C5.0632 1.66819 4.9552 1.42043 4.7632 1.28658C4.53371 1.12567 4.21121 1.07299 4.21121 1.07299L0.780754 1.0972C0.780754 1.0972 0.267761 1.11143 0.0787633 1.32503C-0.0892345 1.51298 0.0637635 1.90314 0.0637635 1.90314C0.0637635 1.90314 2.74873 7.86518 5.79069 10.8711C8.57765 13.6264 11.7426 13.4456 11.7426 13.4456V13.4442Z' fill='white'/>
        </svg>,
    yt:
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_580_508)'>
                <path d='M23.0645 5.00484C22.1982 3.97501 20.5987 3.55493 17.5441 3.55493H6.45569C3.33112 3.55493 1.70454 4.00211 0.841471 5.09852C0 6.16753 0 7.74262 0 9.9226V14.0776C0 18.3009 0.9984 20.4452 6.45569 20.4452H17.5442C20.1931 20.4452 21.661 20.0745 22.6106 19.1657C23.5845 18.2337 24 16.712 24 14.0776V9.9226C24 7.62363 23.9349 6.03924 23.0645 5.00484ZM15.4081 12.5737L10.3729 15.2053C10.2603 15.2641 10.1372 15.2933 10.0143 15.2933C9.87507 15.2933 9.73618 15.2559 9.61316 15.1814C9.38152 15.041 9.24008 14.79 9.24008 14.5191V9.27289C9.24008 9.00254 9.38113 8.75171 9.61231 8.61127C9.84356 8.47083 10.1312 8.46123 10.3711 8.58587L15.4063 11.2005C15.6625 11.3335 15.8234 11.598 15.8237 11.8865C15.8241 12.1753 15.6639 12.4401 15.4081 12.5737Z' fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_580_508'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>,
    dzen:
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
            <g transform='translate(9,9 )'>
                <path fillRule='evenodd' clipRule='evenodd' d='M17.1954 11.4215C18.797 11.2529 20.3985 11.2108 22 11.1265C21.9579 14.2031 20.5671 17.2797 18.2069 19.3027C16.2682 21.0307 13.6973 21.9579 11.1265 22C11.2108 20.0192 11.2108 18.0383 11.5901 16.0996C11.8429 14.751 12.5594 13.4866 13.6552 12.6437C14.6667 11.8851 15.9732 11.5901 17.1954 11.4215Z' fill='white' />
                <path fillRule='evenodd' clipRule='evenodd' d='M0 11.1265C1.89655 11.2108 3.83525 11.2108 5.7318 11.5479C6.99617 11.8008 8.21839 12.3487 9.10345 13.318C10.1149 14.456 10.4943 15.9732 10.6628 17.4483C10.8314 18.9655 10.8736 20.4828 10.9157 22C7.83908 21.9579 4.76245 20.5671 2.73946 18.2069C1.01149 16.3104 0.0421456 13.7395 0 11.1265Z' fill='white' />
                <path fillRule='evenodd' clipRule='evenodd' d='M11.1265 0C14.2874 0.0421456 17.364 1.4751 19.387 3.91954C21.0307 5.85824 21.9579 8.38697 22 10.9157C20.1456 10.8314 18.2912 10.8314 16.4368 10.5364C15.1303 10.3257 13.8659 9.77778 12.9809 8.76628C12.0537 7.79693 11.6322 6.49042 11.4636 5.18391C11.2108 3.45594 11.1686 1.72797 11.1265 0Z' fill='white' />
                <path fillRule='evenodd' clipRule='evenodd' d='M4.72022 1.98084C6.49033 0.716475 8.6819 0.0421456 10.8735 0C10.7892 2.10728 10.8313 4.2567 10.3256 6.32184C9.94627 7.79693 8.97692 9.14559 7.58612 9.86207C6.2796 10.5364 4.80451 10.6628 3.32941 10.7893C2.19148 10.8736 1.09569 10.9157 -0.0422363 10.9157C0.0420549 7.37548 1.85432 3.96169 4.72022 1.98084Z' fill='white' />
            </g>
        </svg>,
    
}

export const SocialIcon = ({ icon = 'tg' }) => {
    return icons[icon]
}
