import axios from 'axios'

export const prod = true

const API_DOMAIN = prod
    ? 'https://api.play-ddx.ru/'
    : 'https://api.ddx-test.ru/'

    // 'http://5.63.153.98:6000'

export const PUBLIC_RECAPTCHA_KEY = '6LfDn8MmAAAAANbd5ztjbGUkrQM-KTEBWnycvU0r'

export const authApi = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_DOMAIN || API_DOMAIN
})