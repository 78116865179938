import { useContext, useEffect, useRef, useState } from 'react'
import './style.scss'
import { arrow, shadowOne, shadowTwo, spinnerInner, spinnerOuter } from './images'
import { calculateSpin, getAngleOfPoint, isCoordinateInsideWheel } from './utils'
import { disableWindowScroll, enableWindowScroll } from 'src/utils'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'

// const MAX_SPIN_SPEED = 720

const spinnerClassNamesObj = {
    'default': 'wheel__spinner',
    'transition-3-5': 'wheel__spinner wheel__spinner-animation_3_5',
    'transition-0-3': 'wheel__spinner wheel__spinner-animation_0_3',
}

const touchBlockClassNamesObj = {
    'default': 'wheel__touch-container',
    'pe-none': 'wheel__touch-container wheel__touch-container--pe-none',
}

export const Wheel = observer(({ hide }) => {
    const { wheelStore } = useContext(AppContext)
    const { spinning, finished, hideWheel } = wheelStore.state
    const isWheelActive = spinning || finished

    const wheelClassNames = 'wheel'
        + (hideWheel ? ' wheel--fade-out' : '')
        + (hide ? ' wheel--hide' : '')

    // const [windowWidth, setWindowWidth] = useState(null)
    // const [isSpinned, setIsSpinned] = useState(false)
    
    const [style, setStyle] = useState({})
    const [spinnerClassNames, setSpinnerClassNames] = useState(spinnerClassNamesObj['default'])
    const [touchBlockClassNames, setTouchBlockClassNames] = useState(touchBlockClassNamesObj['default'])

    const touchPrevAngle = useRef(null)
    const spinAngle = useRef(null)
    const spinAnglePrev = useRef(null)
    const spinSpeed = useRef(null)
    const prevTouchTime = useRef(null)
    
    const [touchStartAngle, setTouchStartAngle] = useState(null)
    const [touchStartTime, setTouchStartTime] = useState(null)
    const [wheelStartAngle, setWheelStartAngle] = useState(0)

    // const [fullSpinsOffset, setFullSpinsOffset] = useState(0)
    const fullSpinsOffset= useRef(null)


    const touchCurrentAngle = useRef(null)

    // определять по ширине окна, если планшет/телефон - крутим с тачскрина, если пк - кнопкой
    // const [isAutoSpin, setIsAutoSpin] = useState(null)
    // const handleModeToggle = () => setIsAutoSpin(prev => !prev)

    const minRange = 1
    const maxRange = 7

    useEffect(() => {
        if (spinning) {
            getPrize()
        }
    }, [spinning])
    // const isSpinBtnDisabled = finished

    // const handleWheelSpinReset = () => {
    //     setIsSpinned(false)
    //     setSpinnerClassNames(spinnerClassNamesObj['default'])
    //     setTouchBlockClassNames(touchBlockClassNamesObj['default'])
    //     setStyle({ transform: `rotate(${0}deg)` })
    // }


    // useEffect(() => {
    //     function handleResize() {
    //         setWindowWidth(window.innerWidth)
    //     }

    //     window.addEventListener('resize', handleResize)
    //     return () => window.removeEventListener('resize', handleResize)
    // }, [])

    const wheelDataReset = () => {
        touchPrevAngle.current = null
        spinAngle.current = null
        spinAnglePrev.current = null
        spinSpeed.current = null
        prevTouchTime.current = null
        fullSpinsOffset.current = 0

        setTouchStartAngle(null)
        setTouchStartTime(null)
        setWheelStartAngle(0)
        wheelStore.resetSpin()
        // setFullSpinsOffset(0)
    }

    const handleWheelReset = () => {
        setSpinnerClassNames(spinnerClassNamesObj['transition-0-3'])
        setTouchBlockClassNames(touchBlockClassNamesObj['pe-none'])
        setStyle({ transform: `rotate(${0}deg)` })

        setTimeout(() => {
            setSpinnerClassNames(spinnerClassNamesObj['default'])
            setTouchBlockClassNames(touchBlockClassNamesObj['default'])
            wheelDataReset()
        }, 301)
    }

    const calcNegativeAngle = (angle) => {
        const maxInput = -180
        const maxOutput = -90

        if (angle > 0) {
            throw new Error('You must pass only negative values as an angle.')
        }

        if (Math.abs(angle) > Math.abs(maxInput)) {
            return -90
        }

        const loweringConstant =  1 - (maxOutput / maxInput)
        const loweringCoefficient = (angle / maxInput) * loweringConstant
        const outputAngle = angle - (angle * loweringCoefficient)

        return outputAngle
    }

    // _touch_end_
    // _touch_end_bc_left_pg_zone_
    const handleWheelControls = (eventName = '') => {
        const timeInTouch = Date.now() - touchStartTime
        const timeFromLastTouchMove = Date.now() - prevTouchTime.current

        if (wheelStartAngle < spinAngle.current) {
            // console.log(eventName + ' angle: ', spinAngle.current - wheelStartAngle, ' time: ', timeInTouch / 1000, 's -->')
            
            const timeInTouchInSeconds = timeInTouch / 1000
            const timeFromLastTouchMoveInSeconds = timeFromLastTouchMove / 1000
            const speed = spinSpeed.current < (maxRange / 3.5 * 360)
                ? spinSpeed.current
                : (maxRange / 3.5 * 360)
            const fullSpinsOffset360Turns = Math.round(spinAngle.current / 360) // проверить
            const fullSpins = Math.round(speed * 3.5 / 360) + fullSpinsOffset360Turns

            // const angle = spinAngle.current - wheelStartAngle
            // const spinSpeed = angle / timeInSeconds
            // const fullSpins = Math.round(spinSpeed * 3.5 / 360)
            // console.log(spinSpeed, ' deg/s ', fullSpins)

            const isTouchTimeAvailable = timeInTouchInSeconds < 5 // 2.5
            const isTimeFromLastTouchMoveAvailable = timeFromLastTouchMoveInSeconds < 0.1

            if (isTimeFromLastTouchMoveAvailable) {
                getPrize(fullSpins || 1) // убрать 1
            } else {
                handleWheelReset()
            }

        } else if (wheelStartAngle > spinAngle.current) {
            const negativeAngle = calcNegativeAngle(spinAngle.current)
            const fullSpins = Math.round(Math.abs(negativeAngle / 90) * maxRange)
            // const fullSpinsOffset360Turns = Math.round(spinAngle.current / 360) // проверить
            getPrize(fullSpins || 1)
            console.log(fullSpins)
        } else {
            handleWheelReset()
            // console.log(eventName + ' angle: ', spinAngle.current - wheelStartAngle, ' time: ', timeInTouch / 1000, 's <--')
        }

        setTimeout(() => {
            enableWindowScroll()
        }, 300)
    }


    // TOUCH START //
    const handleTouchStart = (event) => {
        // event.preventDefault()
        disableWindowScroll()

        // Блок с колесом
        const rect = event.currentTarget.getBoundingClientRect()

        // Координаты касания относительно блока
        const touch = event.touches[0]
        const x = touch.clientX - rect.left 
        const y = touch.clientY - rect.top

        const isInsideWheel = isCoordinateInsideWheel(rect.width, x, y, 15, 20)

        // Если не внутри колеса - выходим из функции
        if (!isInsideWheel) {
            setTouchStartAngle(null)
            return
        }

        // Если колесо уже крутилось - выходим из функции
        if (isWheelActive) {
            return
        }

        // console.log('touch start: ', Math.round(touchPrevAngle.current))
        const angle = getAngleOfPoint(rect.width, x, y)

        setTouchStartAngle(angle)
        touchPrevAngle.current = angle
    }

    // TOUCH MOVE //
    const handleTouchMove = (event) => {
        // event.preventDefault()
        disableWindowScroll()

        // Если нет стартового угла - выходим
        if (touchStartAngle === null) {
            return
        }

        // Если колесо уже крутилось - выходим из функции
        if (isWheelActive) {
            return
        }

        // Блок с колесом
        const rect = event.currentTarget.getBoundingClientRect()
        
        // Координаты касания относительно блока
        const touch = event.touches[0]
        const x = touch.clientX - rect.left
        const y = touch.clientY - rect.top

        const isInsideWheel = isCoordinateInsideWheel(rect.width, x, y, 15, 20)

        if (isInsideWheel) {
            const angle = getAngleOfPoint(rect.width, x, y)
            touchCurrentAngle.current = angle

            
            
            const angleDifference = touchPrevAngle.current - angle
            touchPrevAngle.current = angle

            // console.log(angle, spinAngle.current)

            if (angleDifference > 250) {
                fullSpinsOffset.current += 1
                // setFullSpinsOffset(prevCount => prevCount + 1)
            }

            if (angleDifference < -250) {
                fullSpinsOffset.current -= 1
                // setFullSpinsOffset(prevCount => prevCount - 1)
            }

            // window.angleOfPoint = getAngleOfPoint(rect.width, x, y)

            const currentSpinAngle = wheelStartAngle + (angle - touchStartAngle) + (fullSpinsOffset.current * 360)
            // spinAngle.current = currentSpinAngle >= 0
            //     ? currentSpinAngle
            //     : 0
            spinAngle.current = currentSpinAngle

            // console.log('ANGLE_', currentSpinAngle, angle, spinAngle.current, fullSpinsOffset.current);

            // Если пытаемся крутить против часовой стрелки - выход из функции
            // if ((spinAngle.current + angleDifference) <= 0) {
            //     // console.log('CURRENT__ ', spinAngle.current)
            //     return
            // }

            // Если не установлено время начала действия - устанавливаем
            if (!touchStartTime) {
                setTouchStartTime(Date.now())
            }
                
            if (setTouchStartTime && prevTouchTime.current && spinAnglePrev.current) {
                const timeInTouch = Date.now() - prevTouchTime.current
                const timeInSeconds = timeInTouch / 1000
                const angle = Math.abs(spinAngle.current - spinAnglePrev.current)

                spinSpeed.current = angle / timeInSeconds
                // console.log('_____ ', spinSpeed, angle, timeInSeconds)
            }

            prevTouchTime.current = Date.now()
            spinAnglePrev.current = spinAngle.current

            if (spinAngle.current < 0) {
                // const angle = spinAngle.current / ((spinAngle.current * spinAngle.current) - 2 * spinAngle.current)
                // console.log(spinAngle.current, angle)
                setStyle({ transform: `rotate(${calcNegativeAngle(spinAngle.current)}deg)` })

                if (spinAngle.current <= -180) {
                    handleWheelControls()
                }

            } else {
                setStyle({ transform: `rotate(${spinAngle.current}deg)` })
            }


        // ВЫХОДИМ ЗА ПРЕДЕЛЫ КОЛЕСА В ТАЧ СОБЫТИИ
        } else if (touchStartAngle) {
            // else if (touchStartAngle && spinAngle.current > 0) {
            handleWheelControls()
        }
    }

    // TOUCH END //
    const handleTouchEnd = (event) => {
        // setFullSpinsOffset(0)
        fullSpinsOffset.current = 0

        if (touchStartAngle === null) {
            return
        }

        // Если колесо уже крутилось - выходим из функции
        if (isWheelActive) {
            return
        }

        // if (spinAngle.current <= 0) {
        //     console.log('TURNED BACKWARDS: ', spinAngle.current)
        //     return
        // }

        handleWheelControls()
    }

    const getPrize = (spins) => {
        // Добавить калькуляцию чтобы при малой силе подкручивало
        // к ближайшему призу, а не к рандомному - если их несколько на колесе:
        // то есть придется передавать не spins, а speed внутрь утилиты calculateSpin

        // const res = calculateSpin({ name: '-2000р' }, spins, minRange, maxRange)
        const res = calculateSpin({ name: wheelStore?.prize?.name }, spins, minRange, maxRange)
        setStyle({ transform: `rotate(${res}deg)` })
        wheelStore.startSpin()

        setSpinnerClassNames(spinnerClassNamesObj['transition-3-5'])
        setTouchBlockClassNames(touchBlockClassNamesObj['pe-none'])

        setTimeout(() => {
            wheelStore.finishSpin()
            wheelStore.setPrizeReceived()
        }, 3501)
    }

    return (
        <div className={wheelClassNames}>
            <div
                className={touchBlockClassNames}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            ></div>
            
            <div className='wheel__container'>
                <img src={spinnerOuter} alt='' className='wheel__static' />
                <img src={shadowOne} alt='' className='wheel__shadow-one' />
                <img src={shadowTwo} alt='' className='wheel__shadow-two' />
                <img src={spinnerInner} alt='' className={spinnerClassNames} style={style} />
                <img src={arrow} alt='' className='wheel__arrow' />
            </div>
        </div>
    )
})