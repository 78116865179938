import { authApi } from './config'

// Запросы для пользователей

export const createLead = async (data) => {
    try {
        const response = await authApi.post('/lead', data)
        return response.data
    } catch (err) {
        return err
    }
}