import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { EditClubs, Login, SelectClubs } from './components'

import './style.scss'
import { EditLottery } from './components/EditLottery'
import { AppContext } from 'src'
import { Loader } from 'src/components'

export const Admin = observer(() => {
    const [modal, setModal] = useState(null)
    const { authStore, clubStore, wheelStore } = useContext(AppContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (authStore.isAuth === null) {
            authStore.auth()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (authStore.isAuth) {
            clubStore.getAllClubs()
            clubStore.getSelectedClubs()
        }
    }, [authStore.isAuth]) // eslint-disable-line

    useEffect(() => {
        if (authStore.error === 'Не авторизован' || clubStore.error === 'Не авторизован') {
            navigate('/admin')
        }
    }, [authStore.error, clubStore.error, wheelStore.error]) // eslint-disable-line

    const isLoading = clubStore.loading || authStore.loading || wheelStore.loading

    return (
        authStore.isAuth === null ? <div></div> :
        <div className='admin container'>
            {modal && 
                <div className='overlay'>
                    <div className='container'>
                        {modal}
                    </div>
                </div>}

            {isLoading &&
                <div className='overlay'>
                    <Loader />
                </div>}

            <Routes>
                <Route exact path='/' element={!authStore.isAuth ? <Login /> : <Navigate to='/admin/select' />} />
                <Route exact path='/' element={authStore.isAuth ? <Outlet /> : <Navigate to='/admin' />} >
                    <Route exact path='/edit' element={<EditClubs handleModal={setModal} />} />
                    <Route exact path='/select' element={<SelectClubs />} />
                    <Route exact path='/lottery' element={<EditLottery handleModal={setModal} />} />
                    <Route path='*' element={<Navigate to='/edit' />} />
                </Route>
            </Routes>
        </div>
    )
})

