import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { Block } from 'src/components'

export const EditClub = observer( ({ item, handleModal }) => {
    const [name, setName] = useState(item.name)
    const { clubStore } = useContext(AppContext)

    const canSubmit = (item.name !== name.trim()) && name.trim().length > 5

    const handleSubmit = async () => {
        if (!canSubmit) {
            return
        }
        const data = { id: item.id, name: name.trim() }
        await clubStore.updateOneClub(data)
        if (!clubStore.error) {
            handleModal(null)
        }
        if (clubStore.error === 'Не авторизован') {
            handleModal(null)
        }
    }

    const handleClose = () => {
        clubStore.setError('')
        handleModal()
    }

    return (
        <Block w={400} onClose={() => handleModal(null)}>
            <div className='edit-clubs'>
                <h2 className='title title--sm'>
                    Редактирование клуба <span>(ФУНКЦИЯ НЕДОСТУПНА, ДЛЯ РЕДАКТИРОВАНИЯ ОБРАТИТЕСЬ К АДМИНИСТРАТОРУ)</span>

                    {clubStore.error &&
                        <label className='label' error>{clubStore.error}</label>}
                </h2>

                <div>
                    <label className='label'>Название клуба:</label>
                    <input
                        className='input'
                        disabled
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Введите название клуба'
                    />
                </div>

                <div className='edit-clubs__btns'>
                    <button
                        className='btn'
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                    >
                        Изменить
                    </button>

                    <button
                        className='btn btn--light'
                        onClick={handleClose}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </Block>
    )
} )
