import { Navigate, Route, Routes } from 'react-router-dom'
import { Header, Footer } from 'src/layout'
import { Admin, Main } from 'src/pages'

export const App = () => {
	return (
		<div className='app'>
			<Header />

			<Routes>
				<Route exact path='/' element={<Main />} />
				<Route exact path='/admin/*' element={<Admin />} />
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>

			<Footer />

		</div>
	)
}