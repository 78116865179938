// import { Block, Form, Title, Label, Button } from 'common/components'
// import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { Block } from 'src/components'

export const ResetPrizes = observer( ({ onSubmit, onClose, text = 'Вы действительно хотите сбросить все призы?' }) => {
    return (
        <Block w={400} onClose={() => onClose(null)} >
            <div className='edit-lottery'>
                <h2 className='title title--sm'>
                    Сброс списка призов
                </h2>

                <label className='label'>{text}</label>

                <div className='edit-lottery__btns'>
                    <button className='btn' onClick={onSubmit}>Подтвердить</button>
                    <button className='btn btn--light' onClick={onClose}>Отмена</button>
                </div>
            </div>
        </Block>
    )
} )

