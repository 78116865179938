import {
    authCheck,
    login,
    logout,
} from './auth.api'

import {
    getPrize,
    setPrizeReceived,
    getPrizesByClubId,
    setClubActiveById,
    addPrizeQuantityById,
    resetPrizeQuantityById,
    resetAllPrizeQuantities,
} from './prize.api'

import {
    getSelectedClubs,
    setSelectedClubs,
    getAllClubs,
    createOneClub,
    deleteClubById,
} from './club.api'

import {
    createLead,
} from './lead.api'


export const authApi = {
    authCheck,
    login,
    logout,
}

export const prizeApi = {
    getPrize,
    setPrizeReceived,
    getPrizesByClubId,
    setClubActiveById,
    addPrizeQuantityById,
    resetPrizeQuantityById,
    resetAllPrizeQuantities,
}

export const clubApi = {
    getSelectedClubs,
    setSelectedClubs,
    getAllClubs,
    createOneClub,
    deleteClubById,
}

export const leadApi = {
    createLead,
}
