import './style.scss'
import {
    discount1500Image,
    discount2000Image,
    discount3000Image,
    discountMonthImage,
    merchImage,
    noPrizeImage,
} from './images'

const PRIZES = {
    '-1500р': discount1500Image,
    '-2000р': discount2000Image,
    '-3000р': discount3000Image,
    '1 месяц фитнеса': discountMonthImage,
    'Мерч DDX': merchImage,
    'no-prize': noPrizeImage,
}

export const PrizeView = ({ prizeId = 'no-prize', date }) => {
    const imageSrc = PRIZES[prizeId]

    return (
        <div className='prize-view'>
            {date &&
                <div className='prize-view__date'>{date}</div>}
            <img src={imageSrc} alt='' />
        </div>
    )
}