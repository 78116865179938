import { Checkbox, PhoneInput, Select } from 'src/components'
import './style.scss'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { observer } from 'mobx-react-lite'
import { AppContext } from 'src'
import { useContext, useState } from 'react'
import { emailREGEX, validatePhone } from 'src/utils'
import { useSearchParams } from 'react-router-dom'
import { yandexMetrika } from 'src/analytics'

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export const Form = observer(({ handleThanksBlock, handleErrorModal }) => {
    const { wheelStore, clubStore } = useContext(AppContext)

    const [params] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const isError = error.length > 0

    const isSelectedClubs = clubStore.selectedClubs?.length > 0
    const isPrizeClub = wheelStore.club

    const showClubSelect = isSelectedClubs || isPrizeClub
    
    const formik = useFormik({
        initialValues: { name: '', email: '', phone: '', isClubMember: '', club: wheelStore.club || {}, personalData: true, rim: true },
        validationSchema: Yup.object({
            name: Yup.string().trim().required().min(1).max(100),
            email: Yup.string().matches(emailREGEX).max(50),
            phone: Yup.string().required().test('invalid phone', 'invalid phone', validatePhone),
            club: Yup.mixed().test('club required', 'club required', (val) => {
                return isSelectedClubs ? val.name : true
            }),
            personalData: Yup.bool().test('invalid value', 'invalid value', val => val),
            rim: Yup.bool().required(),
        }),
        onSubmit: async (values, actions) => {
            const utms = {}
            UTM_PARAMS.forEach(param => {
                const value = params.get(param)
                if (value) {
                    utms[param] = value
                }
            })

            const data = {
                name: values.name.trim(),
                email: values.email,
                phone: values.phone.replace(/[^\d]/g, ''),
                personalData: values.personalData,
                rim: values.rim,
                club: values.club?.name,
                ...utms,
            }

            setError('')
            setLoading(true)

            wheelStore.createLead(data)
                .then(res => {
                    if (res.status === 'success') {
                        yandexMetrika.sendForm()
                        handleThanksBlock()
                        return
                    }

                    if (res.response?.data?.message === 'Вы уже отправляли заявку с данного номера') {
                        formik.resetForm()
                        handleErrorModal()
                        return
                    }

                    setError('При отправке данных произошла ошибка')
                })
                .finally(_ => {
                    setLoading(false)
                })
        },
    })

    const selectOptions = wheelStore.prize
        ? [wheelStore.club]
        : [...clubStore.selectedClubs].map(i => ({ ...i, value: i.name }) ) || []

    const submitButtonText = wheelStore.prize
        ? 'Получить скидку!'
        : 'Отправить!' 

    return (
        <form className='form' onSubmit={formik.handleSubmit}>
            <h1 className='title'>
                Анкета
                {/* {JSON.stringify(formik.errors)} */}
                <br/>
                <span>участника</span>
            </h1>

            <div className='form__input-grid'>
                <fieldset className='fieldset'>
                    <label className='label label--req'>Фамилия Имя</label>
                    <input
                        className='input'
                        // tabIndex='1'
                        id='name'
                        name='name'
                        type='text' placeholder='Введите фамилию и имя'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        disabled={loading}
                        error={String(formik.errors.name && formik.touched.name)}
                    />
                </fieldset>

                <fieldset className='fieldset'>
                    <label className='label label--req'>Телефон</label>
                    <PhoneInput
                        // tabIndex='2'
                        error={String(formik.errors.phone && formik.touched.phone)}
                        type='text'
                        placeholder='Укажите телефон'
                        name='phone'
                        id='phone'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        disabled={loading}
                    />
                </fieldset>

                {showClubSelect &&
                    <fieldset className='fieldset'>
                        <label className='label label--req'>Клуб</label>
                        <Select
                            formik
                            error={formik.errors.club && formik.touched.club}
                            placeholder='Выберите клуб'
                            name='club'
                            id='club'
                            onChange={(value) => formik.setFieldValue('club', value)}
                            onBlur={formik.handleBlur} 
                            value={formik.values.club}
                            options={selectOptions}
                            disabled={loading || wheelStore.club}
                        />
                    </fieldset>}

                <fieldset className='fieldset'>
                    <label className='label'>E-mail</label>
                    <input
                        className='input'
                        error={String(formik.errors.email)}
                        type='email'
                        placeholder='Укажите E-mail'
                        name='email'
                        id='email'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        disabled={loading}
                    />
                </fieldset>
            </div>

            <fieldset className='fieldset fieldset--checkbox'>
                <Checkbox
                    name='personalData'
                    id='personalData'
                    checked={formik.values.personalData}
                    onClick={(value) => formik.setFieldValue('personalData', value)}
                    disabled={loading}
                >
                    Даю согласие на обработку <a href='https://www.ddxfitness.ru/policy/?utm_source=event&utm_campaign=play_ddx' target='_blank' rel='noreferrer'>персональных данных</a>
                </Checkbox>

                <Checkbox
                    name='rim'
                    id='rim'
                    checked={formik.values.rim}
                    onClick={(value) => formik.setFieldValue('rim', value)}
                    disabled={loading}
                >
                    Даю согласие на получение <a href='https://www.ddxfitness.ru/consent-advertising/?utm_source=event&utm_campaign=play_ddx' target='_blank' rel='noreferrer'>РИМ</a>
                </Checkbox>
            </fieldset>

            <fieldset className='fieldset'>
                {isError &&
                    <label className='label label--error'>{error}</label>}

                <button
                    className='btn'
                    type='submit'
                    disabled={Object.keys(formik.errors).length || loading}
                >
                    {submitButtonText}
                </button>
            </fieldset>
        </form>
    )
})