import { NavLink } from 'react-router-dom'
import { BurgerBtn } from 'src/components'

const MobileMenu = ({ active, isMenu, onClose, handleLogout }) => {
    const mobileMenuClassNames = 'mobile-menu'
        + (isMenu ? ' mobile-menu--active' : '')

    return (
        <div className={mobileMenuClassNames}>
            
            <div className='nav' onClick={onClose}>
                <NavLink className={active} to='/admin/select'>Выбор&nbsp;клубов</NavLink>
                <NavLink className={active} to='/admin/edit'>Редактирование</NavLink>
                <NavLink className={active} to='/admin/lottery'>Адская рулетка</NavLink>
                <button onClick={handleLogout} className='nav__item'>Выйти</button>
            </div>
        </div>
    )
}

export const MobileNavigation = ({ toggleMenu, onClose, active, isMenu, handleLogout }) => {
    return (
        <>
            <BurgerBtn isMenu={isMenu} onClick={toggleMenu} /> 

            <MobileMenu
                active={active}
                isMenu={isMenu}
                onClose={onClose}
                handleLogout={handleLogout}
            />
        </>
    )
}