import { authApi } from './config'

// Запросы для пользователей

export const getPrize = async (clubId) => {
    try {
        const response = await authApi.get('/prize/get/' + clubId)
        return response.data
    } catch (err) {
        return err
    }
}

export const setPrizeReceived = async () => {
    try {
        const response = await authApi.post('/prize/set/')
        return response.data
    } catch (err) {
        return err
    }
}

// Запросы для админов

export const getPrizesByClubId = async (clubId) => {
    try {
        const response = await authApi.get('/prize/status/' + clubId)
        return response.data
    } catch (err) {
        return err
    }
}

export const setClubActiveById = async (clubId, status, reset = false) => {
    try {
        const response = await authApi.post('/prize/status/' + clubId, {
            status,
            reset,
        })
        return response.data
    } catch (err) {
        return err
    }
}

export const addPrizeQuantityById = async (prizeId, quantity) => {
    try {
        const response = await authApi.post('/prize/add-quantity/' + prizeId, {
            quantity,
        })
        return response.data
    } catch (err) {
        return err
    }
}

export const resetPrizeQuantityById = async (prizeId) => {
    try {
        const response = await authApi.delete('/prize/reset-quantity/' + prizeId)
        return response.data
    } catch (err) {
        return err
    }
}

export const resetAllPrizeQuantities = async (clubId) => {
    try {
        const response = await authApi.delete('/prize/reset-all/' + clubId)
        return response.data
    } catch (err) {
        return err
    }
}