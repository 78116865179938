// import { Block, Form, Title, Label, Button, Input } from 'common/components'
// import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { AppContext } from 'src'
import { Block } from 'src/components'

export const AddClub = observer( ({ handleModal }) => {
    const [name, setName] = useState('')
    const { clubStore } = useContext(AppContext)
    const canSubmit = name.trim().length > 5

    const handleForm = async () => {
        if (!canSubmit) {
            return
        }
        await clubStore.createOneClub(name)
        if (!clubStore.error) {
            handleModal(null)
        }
        if (clubStore.error === 'Не авторизован') {
            handleModal(null)
        }
    }

    const handleClose = () => {
        clubStore.setError('')
        handleModal(null)
    }
    
    return (
        <Block w={400} onClose={() => handleModal(null)} >
            <div className='edit-clubs'>
                <h2 className='title title--sm'>
                    Добавление клуба

                    {clubStore.error &&
                        <label className='label' error>{clubStore.error}</label>}
                </h2>

                <fieldset className='fieldset'>
                    <label className='label'>Название:</label>
                    <input
                        className='input'
                        autoFocus
                        placeholder='Введите название'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </fieldset>

                <div className='edit-clubs__btns'>
                    <button
                        className='btn'
                        disabled={!canSubmit}
                        onClick={handleForm}
                    >Добавить</button>

                    <button
                        className='btn btn--light'
                        onClick={handleClose}
                    >Отмена</button>
                </div>
            </div>
        </Block>
    )
} )

