import React, { useContext, useState } from 'react'
import './style.scss'
import memeImage from './images/meme.webp'

import { Block, ClubList, Search } from 'src/components'
import { AddClub } from './AddClub'
import { EditClub } from './EditClub'
import { observer } from 'mobx-react-lite'
import { DeleteClub } from './DeleteClub'

import { AppContext } from 'src'

export const EditClubs = observer( ({ handleModal }) => {
    const { clubStore } = useContext(AppContext)
    
    const [searchString, setSearchString] = useState('')
    const handleSearchInput = (event) => setSearchString(event.target.value)
    const filteredClubs = !searchString.length ? clubStore.clubs : [...clubStore.clubs].filter(item =>
        item.name.toLowerCase().includes(searchString.toLowerCase().trim()))


    return (
        <Block w={500}>
            <div className='edit-clubs'>
                <h2 className='title title--sm'>Редактирование клубов</h2>
                
                <div className='edit-clubs__meme'>
                    <a
                        className='link link--center'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.ddxfitness.ru/clubs'
                    >
                        <img
                            src={memeImage}
                            alt='meme'
                        ></img>
                    </a>

                    <a
                        className='link'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.ddxfitness.ru/clubs'
                    >
                        Список клубов
                    </a>
                </div>

                <div className='edit-clubs__fields'>
                    <fieldset className='fieldset'>
                        <label className='label'>Поиск по клубам:</label>
                        <Search
                            value={searchString}
                            onChange={handleSearchInput}
                        />
                    </fieldset>

                    <fieldset className='fieldset'>
                        <label className='label'>Клубы:</label>
                        <ClubList
                            items={filteredClubs}
                            onEdit={(i) => handleModal(<EditClub handleModal={() => handleModal(null)} item={i} />)}
                            onDelete={(i) => handleModal(<DeleteClub handleModal={() => handleModal(null)} item={i} />)}
                        />
                    </fieldset>
                </div>

                <button
                    className='btn'
                    onClick={_ => handleModal(
                        <AddClub handleModal={() => handleModal(null)} />)}
                >
                    Добавить клуб
                </button>
            </div>
        </Block>
    )
} )
