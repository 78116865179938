import './style.scss'

export const Search = ({ value, onChange }) => {
    return (
        <div className='search'>
            <svg className='search-icon' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='11' cy='11' r='7' stroke='#AFAFE8' strokeWidth='2' />
                <path d='M20 20L17 17' stroke='#AFAFE8' strokeWidth='2' strokeLinecap='round' />
            </svg>

            <input
                className='input'
                value={value}
                onChange={onChange}
                placeholder='Поиск...'
                type='text'
            />
        </div>
    )
}