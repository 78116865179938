import { ClubItem } from './ClubItem'
import './style.scss'

export const ClubList = ({ items = [], onEdit, onDelete, onAdd, notFound = 'Ничего не найдено' }) => {
    const list = [...items]
        .sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)
        .map(item => <ClubItem
                        key={item.id}
                        item={item}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onAdd={onAdd}
                    />)
    return (
        <div className='club-list'>
            <div className="club-list__container">
                {items.length
                    ? list
                    : <div className='club-list__item club-list__item--null'>{notFound}</div>}
            </div>
        </div>
    )
}
