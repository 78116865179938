// import { Block, Form, Title, Label, Button, Input } from 'common/components'
// import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { AppContext } from 'src'
import { Block } from 'src/components'

export const AddPrizes = observer( ({ item, handleModal }) => {
    const [quantity, setQuantity] = useState('')
    const { wheelStore } = useContext(AppContext)
    const canSubmit = quantity > 0 && quantity <= 100

    const handleForm = async () => {
        if (!canSubmit) {
            return
        }
        await wheelStore.addPrizeQuantityById(item.id, Number(quantity))
        if (!wheelStore.error) {
            handleModal(null)
        }
        if (wheelStore.error === 'Не авторизован') {
            handleModal(null)
        }
    }

    const handleInput = (e) => {
        setQuantity(e.target.value.replace(/[^\d]/g, ''))
    }

    const handleClose = () => {
        wheelStore.setError('')
        handleModal(null)
    }
    
    return (
        <Block w={400} onClose={() => handleModal(null)} >
            <div className='edit-lottery'>
                <h2 className='title title--sm'>
                    Добавление призов
                    {wheelStore.error && <label className='label label--error' error>{wheelStore.error}</label>}
                </h2>

                <fieldset className='fieldset'>
                    <label className='label'>Количество:</label>
                    <input
                        className='input'
                        autoFocus
                        placeholder='Введите количество'
                        value={quantity}
                        onChange={handleInput}
                    />
                </fieldset>

                <div className='edit-lottery__btns'>
                    <button className='btn' disabled={!canSubmit} onClick={handleForm}>Добавить</button>
                    <button className='btn btn--light' onClick={handleClose}>Отмена</button>
                </div>
            </div>
        </Block>
    )
} )

